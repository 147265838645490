#blog-header-container{
    background: $iceBlue;
    height: 280px;
    >div{
        display: table;
        width: 100%;
        height: 100%;
        >div{
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            h1{
                margin-bottom: 0;
            }
            h2{
                margin-top: 20px;
            }
        }
    }
}

#blog-filter-container{
    padding: 25px 0;
    background: $teal;
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            >div{
                width: 49%;
                &:nth-of-type(2n){
                    margin-left: 2%;
                }
                label{
                    color: $white;
                    margin-bottom: 5px;
                }
                .select-wrapper{
                    width: 100%;
                    position: relative;
                    height: 40px;
                    border-radius: 5px;
                    &:after{
                        position: absolute;
                        content: '\e90c';
                        top: 8px;
                        right: 10px;
                        font-family: $iconFont;
                        color: $teal;
                    }
                    select{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 40px;
                        border-radius: 5px;
                        border: none;
                        padding: 0 15px;
                        border: 1px solid rgba(43, 122, 120, 0.3);
                        @include transition(all, 300ms, ease-in-out);
                        &:focus{
                            border: 1px solid rgba(43, 122, 120, 0.8);
                            @include transition(all, 300ms, ease-in-out);
                        }
                    }
                }
            }
        }
    }
}

#blog-list-wrapper{
    padding: 0 0 80px;
    #blog-list-container{
        padding: 50px 0 80px;
        .blog-list-item{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 15px;
            padding: 25px;
            border-radius: 10px;
            background: $white;
            @include align-center;
            @include transition(all, 300ms, ease-in-out);
            &:hover{
                background: $iceBlue;
                @include transition(all, 300ms, ease-in-out);
            }
            >div{
                &:first-child{
                    width: 330px;
                    a{
                        position: relative;
                        height: 200px;
                        display: inline-block;
                        width: 100%;
                        >span{
                            height: 200px !important;
                        }
                    }
                    img{
                        border-radius: 10px;
                        width: 100%;
                        object-fit: cover;
                        height: 200px !important;
                    }
                }
                &:last-child{
                    width: calc(100% - 330px);
                    padding-left: 30px;
                    h3{
                        margin-bottom: 10px;
                    }
                    a{
                        color: $black;
                        &:hover{
                            color: $teal;
                        }
                    }
                    .blog-list-details{
                        margin-bottom: 10px;
                        &+p{
                            margin-bottom: 0;
                        }
                        div{
                            display: inline;
                            a{
                                &:last-child{
                                    span{
                                        display: none;
                                    }
                                }
                            }
                        }
                        span{
                            display: inline-block;
                            margin: 0 5px;
                            position: relative;
                            top: -1px;
                            color: $black;
                            &.sep{
                                margin: 0;
                            }
                        }
                        p{
                            display: inline-block;
                            margin-bottom: 0;
                            color: $black;
                        }
                        a{
                            color: $teal;
                        }
                    }
                    .button{
                        border-radius: 20px;
                        background-color: $white;
                        padding: 10px 20px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    #blog-list-pagination{
        padding: 35px 0;
        background: $iceBlue;
        >div{
            >div{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                >div{
                    width: 50%;
                    &:last-child{
                        text-align: right;
                    }
                    button{
                        @include green-button;
                    }
                }
            }
        }
    }
}

#single-blog-header-container{
    background: $iceBlue;
    padding: 50px 0 0;
    >div{
        z-index: 10;
    }
    &.video-overlap{
        padding: 50px 0 200px;
    }
    &.no-image{
        padding: 50px 0;
    }
    #single-blog-back{
        margin-bottom: 30px;
        @include green-arrow-button;
        &:hover{
            .icon-arrow{
                left: -5px;
            }
        }
        .icon-arrow{
            @include rotate(180deg);
            left: 0;
        }
    }
    #single-blog-details{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        margin-top: 10px;
        >div{
            &:first-child{
                width: 60px;
                img{
                    width: 60px;
                    height: 60px;
                    min-height: 60px !important;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            &:last-child{
                width: calc(100% - 60px);
                padding-left: 20px;
                p{
                    margin-bottom: 0;
                }
                >div{
                    span{
                        display: inline-block;
                        margin: 0 5px;
                        position: relative;
                        top: -1px;
                        color: $black;
                        &.sep{
                            margin: 0;
                        }
                    }
                    p{
                        margin-bottom: 0;
                        color: $black;
                        display: inline-block;
                    }
                    div{
                        display: inline;
                        a{
                            &:last-child{
                                span{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #single-blog-featured-image-container{
        top: 100px;
        position: relative;
        margin-top: -50px;
        height: 400px;
        img{
            border-radius: 10px;
            object-fit: cover;
        }
        p{
            font-style: italic;
            margin-top: 10px;
        }
    }
}

/*! purgecss start ignore */
#single-blog-content-container{
    padding: 100px 0 50px;
    &.no-image{
        padding: 50px 0;
    }
    &.video-overlap{
        top: -250px;
        margin-bottom: -250px;
    }
    #simple-blog-content{
        padding: 40px 0;
    }
    #single-blog-post-builder-container{
        .single-blog-content-row{
            &.accordions-type{
                padding: 50px 0;
                >div{
                    &:last-child{
                        margin-top: 40px;
                    }
                }
                .accordion{
                    border-bottom: 1px solid rgba(43, 122, 120, 0.2);
                    .accordion-header{
                        display: inline-block;
                        width: 100%;
                        padding: 20px 40px 20px 0;
                        color: $black;
                        position: relative;
                        text-decoration: none;
                        &.active{
                            color: $teal;
                            &:after{
                                @include rotate(180deg);
                                transition: all 0.3s ease-out;
                            }
                        }
                        &:after{
                            position: absolute;
                            content: '\e90f';
                            font-family: $iconFont;
                            top: 27px;
                            right: 0;
                            font-size: 16px;
                            color: $teal;
                            transition: all 0.3s ease-out;
                        }
                        h3{
                            margin-bottom: 0;
                        }
                    }
                    .accordion-content{
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.5s ease-out;
                        padding: 0 60px 0 0;
                        &.active{
                            max-height: 2800px;
                            transition: all 0.5s ease-in;
                        }
                    }
                }
            }
            &.blockquote-type{
                padding: 20px 0;
            }
            &.call-to-action-type{
                text-align: center;
                margin: 30px auto;
                border-radius: 10px;
                padding: 70px;
                position: relative;
                &.has-image{
                    background-size: cover;
                    background-position: 50%;
                    position: relative;
                    &:before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        background: rgba(23, 36, 42, 0.5);
                        display: inline-block;
                        top: 0;
                        left: 0;
                        z-index: 100;
                    }
                    .single-blog-content-background-image{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        img{
                            border-radius: 10px;
                            object-fit: cover;
                            object-position: 50%;
                        }
                    }
                }
                &:not(.has-image){
                    background: $teal;
                    position: relative;
                    &:after{
			            position: absolute;
			            content: '';
			            right: 0px;
			            bottom: 0;
			            background-image: url('../images/footer-feather.svg');
			            background-size: cover;
			            background-position: top center;
			            width: 300px;
			            height: 250px;
			            opacity: 0.1;
			        }
                    .button.green{
                        &:hover{
                            border: 1px solid $teal;
                        }
                    }
                }
                h1, h2, h3, h4, h5, h6, p{
                    color: $white;
                    z-index: 100;
                    position: relative;
                }
                a:not(.button){
                    color: $white;
                }
                .button{
                    z-index: 100;
                    position: relative;
                }
            }
            &.content-type{
                padding: 20px 0;
                img{
                    height: auto !important;
                }
            }
            &.fact-sheet-type{
                padding: 20px 0 50px;
                >div{
                    background: $iceBlue;
                    border-radius: 10px;
                    padding: 40px 70px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    @include align-center;
                    >div{
                        &:first-child{
                            width: 70%;
                            p, h4{
                                margin-bottom: 0;
                            }
                        }
                        &:last-child{
                            width: 30%;
                            text-align: right;
                        }
                    }
                }
            }
            &.split-content-type{
                padding: 20px 0;
                >div{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    @include align-center;
                    >div{
                        width: 48%;
                        &:nth-of-type(2n-1){
                            padding-right: 30px;
                        }
                        &:nth-of-type(2n){
                            margin-left: 2%;
                            padding-left: 30px;
                        }
                        blockquote{
                            left: -30px;
                            position: relative;
                        }
                    }
                }
            }
            &.video-type{
                padding: 40px 0;
                .video-wrapper{
                    p{
                        margin: 0;
                    }
                    >div{
                        position: relative;
                        padding-bottom: 56.25%;
                        overflow: hidden;
                        border-radius: 10px;
                        iframe{
                            overflow: hidden;
                            border: 0;
                            align-self: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}
/*! purgecss end ignore */

#single-blog-author-container{
    background: $iceBlue;
    border-radius: 10px;
    padding: 50px 30px;
    margin-bottom: 50px;
    h2{
        text-align: center;
        em{
            color: $teal;
            font-style: normal;
        }
    }
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        margin-top: 40px;
        >div{
            &:first-child{
                width: 230px;
                img{
                    width: 100%;
                    max-width: 230px;
                    border-radius: 50%;
                }
            }
            &:last-child{
                width: calc(100% - 230px);
                padding: 0 0 0 30px;
                p{
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

#related-blog-container{
    background: $iceBlue;
    padding: 80px 0 100px;
    h2{
        text-align: center;
        em{
            color: $teal;
            font-style: normal;
        }
    }
    >div{
        >div{
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            >div{
                width: 49%;
                &:nth-of-type(2n){
                    margin-left: 2%;
                }
                &.blog-list-item{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    @include align-center;
                    margin-bottom: 15px;
                    padding: 25px;
                    border-radius: 10px;
                    background: $iceBlue;
                    @include transition(all, 300ms, ease-in-out);
                    &:hover{
                        background: $white;
                        @include transition(all, 300ms, ease-in-out);
                    }
                    >div{
                        &:first-child{
                            width: 250px;
                            a{
                                height: 150px;
                                width: 100%;
                                display: inline-block;
                                position: relative;
                            }
                            img{
                                border-radius: 10px;
                                object-fit: cover;
                            }
                        }
                        &:last-child{
                            width: calc(100% - 250px);
                            padding-left: 30px;
                            h3{
                                margin-bottom: 10px;
                                font-size: 18px;
                            }
                            a{
                                color: $black;
                                &.title-link{
                                    &:hover{
                                        color: $teal;
                                    }
                                }
                            }
                            .blog-list-details{
                                margin-bottom: 10px;
                                span{
                                    display: inline-block;
                                    margin: 0 5px;
                                    position: relative;
                                    top: -1px;
                                    color: $black;
                                    &.sep{
                                        margin: 0;
                                    }
                                }
                                div{
                                    display: inline;
                                    a{
                                        &:last-child{
                                            span{
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                p{
                                    margin-bottom: 0;
                                    color: $black;
                                    display: inline-block;
                                }
                                a{
                                    color: $teal;
                                }
                            }
                            .button{
                                border-radius: 20px;
                                background-color: $white;
                                padding: 10px 20px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1170px){
    #blog-header-container{
        padding: 0 20px;
    }
    #blog-filter-container{
        padding: 25px 20px;
    }
    #blog-list-wrapper{
        #blog-list-container{
            padding: 50px 20px 80px;
        }
        #blog-list-pagination{
            padding: 35px 20px;
        }
    }
    #related-blog-container{
        padding: 80px 20px 100px;
        >div{
            >div{
                >div{
                    &.blog-list-item{
                        >div{
                            &:last-child{
                                .blog-list-details{
                                    div{
                                        display: block;
                                        span{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #single-blog-header-container{
        &.no-image{
            padding: 50px 20px;
        }
        &.video-overlap{
            padding: 50px 20px 200px;
        }
    }
}

@media screen and (max-width: 1080px){
    #related-blog-container{
        >div{
            >div{
                >div{
                    &.blog-list-item{
                        width: 100%;
                        &:nth-of-type(2n){
                            margin-left: 0;
                            margin-top: 30px;
                        }
                        >div{
                            &:last-child{
                                .blog-list-details{
                                    div{
                                        display: inline-block;
                                        span{
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1010px){
    #single-blog-author-container{
        max-width: calc(100% - 40px);
    }
}

@media screen and (max-width: 900px){
    /*! purgecss start ignore */
    #single-blog-content-container{
        #single-blog-post-builder-container{
            .single-blog-content-row{
                &.split-content-type{
                    >div{
                        >div{
                            &:first-child, &:last-child{
                                width: 100%;
                                padding: 0;
                            }
                            &:last-child{
                                margin: 20px 0 0;
                            }
                            blockquote{
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    /*! purgecss end ignore */
    #single-blog-author-container{
        padding: 50px 20px;
        >div{
            >div{
                &:first-child{
                    img{
                        left: 0;
                    }
                }
                &:last-child{
                    padding: 0 0 0 40px;
                }
            }
        }
    }
    #related-blog-container{
        padding: 80px 20px 100px;
    }
}

@media screen and (max-width: 870px){
    #single-blog-header-container{
        padding: 50px 20px 0;
    }
    /*! purgecss start ignore */
    #single-blog-content-container{
        padding: 150px 20px 50px;
        &.no-image{
            padding: 50px 20px;
        }
        &.video-overlay{
            padding: 50px 20px;
        }
    }
    /*! purgecss end ignore */
}

@media screen and (max-width: 767px){
    #blog-header-container{
        height: 220px;
    }
    #blog-filter-container{
        >div{
            >div{
                >div{
                    &:first-child, &:last-child{
                        width: 100%;
                    }
                    &:last-child{
                        margin: 20px 0 0;
                    }
                }
            }
        }
    }
    #blog-list-wrapper{
        padding: 0 0 50px;
        #blog-list-container{
            padding: 50px 10px;
            .blog-list-item{
                background: $iceBlue;
                padding: 20px;
                >div{
                    &:first-child, &:last-child{
                        width: 100%;
                    }
                    &:first-child{
                        a{
                            height: 180px;
                            >span{
                                height: 180px !important;
                            }
                        }
                        img{
                            width: 100%;
                            height: 180px !important;
                            object-fit: cover;
                        }
                    }
                    &:last-child{
                        padding: 20px 0 0;
                        .blog-list-details{
                            a, span{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        #blog-list-pagination{
            >div{
                >div{
                    >div{
                        &:first-child, &:last-child{
                            button{
                                min-width: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
    #single-blog-header-container{
        &.video-overlap{
            padding: 50px 20px 150px;
        }
        #single-blog-featured-image-container{
            top: 150px;
            margin-top: -100px;
            height: 250px;
        }
    }
    /*! purgecss start ignore */
    #single-blog-content-container{
        &.video-overlap{
            top: -200px;
            padding: 50px 20px;
        }
        #simple-blog-content{
            padding: 30px 0;
        }
        #single-blog-post-builder-container{
            .single-blog-content-row{
                &.accordions-type{
                    .accordion{
                        .accordion-header{
                            &:after{
                                font-size: 14px;
                                top: 29px;
                            }
                        }
                    }
                }
                &.call-to-action-type{
                    padding: 50px 20px;
                }
                &.fact-sheet-type{
                    >div{
                        padding: 40px 20px;
                        >div{
                            &:first-child, &:last-child{
                                width: 100%;
                                text-align: center;
                            }
                            &:last-child{
                                padding: 30px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
    /*! purgecss end ignore */
    #single-blog-author-container{
        >div{
            >div{
                &:first-child, &:last-child{
                    width: 100%;
                    text-align: center;
                }
                &:first-child{
                    max-width: 160px;
                    margin: 0 auto;
                }
                &:last-child{
                    padding: 30px 0 0;
                }
            }
        }
    }
    #related-blog-container{
        padding: 60px 20px 80px;
        >div{
            >div{
                margin-top: 30px;
                >div{
                    &.blog-list-item{
                        width: 100%;
                        background: $white;
                        padding: 20px;
                        >div{
                            &:first-child, &:last-child{
                                width: 100%;
                            }
                            &:first-child{
                                a{
                                    height: 180px;
                                }
                                img{
                                    width: 100%;
                                    height: 180px;
                                    object-fit: cover;
                                }
                            }
                            &:last-child{
                                padding: 20px 0 0;
                                .blog-list-details{
                                    div{
                                        a{
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 360px){
    #blog-list-wrapper{
        #blog-list-container{
            .blog-list-item{
                >div{
                    &:last-child{
                        .blog-list-details{
                            span{
                                display: none;
                            }
                            p{
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    #single-blog-header-container{
        #single-blog-featured-image-container{
            height: 200px;
        }
    }
    #related-blog-container{
        >div{
            >div{
                >div{
                    &.blog-list-item{
                        >div{
                            &:last-child{
                                .blog-list-details{
                                    p{
                                        display: block;
                                        width: 100%;
                                    }
                                    div{
                                        span{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}